import React from 'react';
import clsx from 'clsx';
import { CardColor } from 'components/CardFlow/Customize/cardVersions';
import Button from 'components/Button';
import { useNavigate } from 'hooks/useNavigate';
import { RoutePath } from 'enums/Routes';
import { ReactComponent as GoldCard } from 'images/cards/gold-card-front.svg';
import { ReactComponent as SilverCard } from 'images/cards/light-purple-card-front.svg';
import { ReactComponent as PurpleCard } from 'images/cards/purple-silver-card-front.svg';
import { ReactComponent as CheckIcon } from 'images/check-icon.svg';

import { getActiveReward, rewards } from 'components/CardFlow/ReferralProgram/ReferralProgram';
import { useSelector } from 'react-redux';
import { getCardData } from 'selectors/getCardData';
import { getApplicationData } from 'selectors/getApplicationData';
import Loader from 'components/Loader';

import styles from './PlanneryCardSummary.module.scss';

const getCardPreview = (cardText: string, cardColor?: CardColor) => {
  let selectedCard;
  let cardNameStyle;
  switch (cardColor) {
    case CardColor.Gold:
      selectedCard = <GoldCard className={styles.card} />;
      cardNameStyle = styles.gold;
      break;
    case CardColor.PurpleSilver:
      selectedCard = <SilverCard className={styles.card} />;
      cardNameStyle = styles.silver;
      break;
    case CardColor.LightPurple:
      selectedCard = <PurpleCard className={styles.card} />;
      cardNameStyle = styles.purple;
      break;
    default:
      selectedCard = <GoldCard className={styles.card} />;
      cardNameStyle = styles.gold;
      break;
  }
  return (
    <div className={styles.cardPreviewContainer}>
      <div className={styles.cardPreview}>{selectedCard}</div>
      <p className={clsx(styles.cardText, cardNameStyle)}>{cardText}</p>
    </div>
  );
};

const PlanneryCardSummary = () => {
  const navigate = useNavigate();
  const { application } = useSelector(getApplicationData);
  const {
    cardColor,
    waitListPosition,
    borrowerCredentials: credentials,
    referralRewards,
    referredApplicants,
    isLoading,
  } = useSelector(getCardData);

  const { borrowerFirstName: firstName, borrowerLastName: lastName } = application!;

  const cardText = `${firstName} ${lastName}, ${credentials}`;
  const activeReward = getActiveReward(referralRewards);

  const thanksLabel = () => {
    switch (referredApplicants?.length) {
      case 0:
        return 'Refer 3 friends to get your pair of Gales shoes.';
      case 1:
        return `Thanks for referring ${
          referredApplicants![0].firstName
        }. Two more referral to earn your free pair of Gales shoes!`;
      case 2:
        return `Thanks for referring ${referredApplicants![0].firstName} and ${
          referredApplicants![1].firstName
        }. One more referral to earn your free pair of Gales shoes!`;
      case 3:
        return `Thanks for referring ${referredApplicants![0].firstName}, ${referredApplicants![1].firstName} and ${
          referredApplicants![2].firstName
        }. You've earned your pair of Gales shoes!`;
      default:
        return 'Refer 3 friends to get your pair of Gales shoes.';
    }
  };

  const handleClick = () => {
    navigate(RoutePath.ReferralProgram);
  };

  if (isLoading) {
    return (
      <div className={clsx(styles.container, styles.loader)}>
        <Loader color="#795af7" />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <p className={styles.title}>Plannery Card</p>

      <p className={styles.subtitle}>
        You're {waitListPosition ? <span className={styles.bold}>#{waitListPosition}</span> : 'already'} on the
        waitlist.
      </p>

      {getCardPreview(cardText, cardColor)}

      <p className={styles.thanksLabel}>{thanksLabel()}</p>

      <div className={styles.stepperContainer}>
        {rewards(waitListPosition).map((reward, index) => {
          const redeemed = referralRewards && referralRewards[index].redeemed;
          return (
            <div
              key={`reward-${index}`}
              className={clsx(styles.stepperItem, {
                [styles.rewardActive]: activeReward === reward.id,
                [styles.rewardCompleted]: redeemed,
              })}
            >
              <div className={styles.iconContainer}>{redeemed ? <CheckIcon /> : reward.icon}</div>
            </div>
          );
        })}
      </div>
      <Button className={styles.button} onClick={handleClick}>
        {referredApplicants && referredApplicants.length > 0 ? 'Refer another friend' : 'Continue'}
      </Button>
    </div>
  );
};

export default PlanneryCardSummary;
