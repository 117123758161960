import React, { useState } from 'react';
import { ReactComponent as CheckIcon } from 'images/check-icon.svg';
import clsx from 'clsx';

import styles from './ReferralProgram.module.scss';

interface RewardItemProps {
  id: string;
  title: string | JSX.Element;
  description: string | JSX.Element;
  showMoreContent?: string | JSX.Element;
  redeemed?: boolean;
  icon: JSX.Element;
  activeReward?: string | null;
}

const RewardItem = ({ id, title, description, showMoreContent, icon, redeemed, activeReward }: RewardItemProps) => {
  const [showMore, setShowMore] = useState<boolean>(false);

  return (
    <div
      className={clsx(styles.reward, {
        [styles.rewardActive]: activeReward === id,
        [styles.rewardCompleted]: redeemed,
      })}
    >
      <div className={styles.iconContainer}>{redeemed ? <CheckIcon /> : icon}</div>
      <div className={styles.rewardData}>
        <p className={styles.rewardTitle}>{title}</p>
        <span className={styles.rewardDescription}>
          {description}{' '}
          {!showMore && showMoreContent && (
            <span className={styles.highlighted} onClick={() => setShowMore(!showMore)}>
              Show More {'>'}
            </span>
          )}
        </span>
        {showMore && <span className={clsx(styles.rewardDescription, styles.rewardShowMore)}>{showMoreContent}</span>}
      </div>
    </div>
  );
};

export default RewardItem;
