import React from 'react';
import FormNavigation from 'components/FormNavigation';

import ReferralProgram from './ReferralProgram';

import styles from './ReferralProgram.module.scss';

const ReferralProgramScreen = () => (
  <div className={styles.container}>
    <FormNavigation />
    <ReferralProgram />
  </div>
);

export default ReferralProgramScreen;
