import React, { useState } from 'react';
import Input from 'components/Input';
import Button from 'components/Button';
import { ReactComponent as WaitListIcon } from 'images/waitlist.svg';
import { ReactComponent as CameraIcon } from 'images/camera.svg';
import { ReactComponent as ShoesIcon } from 'images/shoes.svg';
import { ReactComponent as GalesIcon } from 'images/gales.svg';
import { ReactComponent as GalesShoes } from 'images/gales-shoes.svg';
import { EMAIL_PATTERN } from 'components/LoanForm/YourContact/YourContact';
import ShareModal from 'components/ShareModal';
import { SHARE_MESSAGE } from 'components/ShareModal/ShareModal';
import { useSelector } from 'react-redux';
import { getCardData } from 'selectors/getCardData';
import { formatNumber } from 'utils/formatNumber';
import { ReferralProgramReward } from 'handlers/cardData';
import clsx from 'clsx';

import RewardItem from './RewardItem';

import styles from './ReferralProgram.module.scss';

export const getActiveReward = (referralRewards: ReferralProgramReward[] | undefined): string | null => {
  if (!referralRewards) {
    return null;
  }

  return referralRewards.find((reward) => !reward.redeemed)?.id || null;
};

export const rewards = (waitListPosition?: number) => [
  {
    id: 'MoveUpWaitlist',
    title: 'Move up the wait list',
    description: `There are ${
      waitListPosition ? formatNumber(waitListPosition - 1) : ''
    } people in front of you. You'll jump ahead with each referral.`,
    icon: <WaitListIcon />,
  },
  {
    id: 'CameoFeaturing',
    title: 'Be featured on Cameo',
    description: <>Each weak we'll select one nurse to be featured on Cameo. </>,
    showMoreContent: (
      <>
        On Cameo, you'll be featured as a healthcare hero and have the opportunity to be paid to create videos for
        people you inspire. <div className={clsx(styles.highlighted, styles.visitLink)}>Visit Cameo {'>'}</div>
      </>
    ),
    icon: <CameraIcon />,
  },
  {
    id: 'FreeGales',
    title: (
      <span className={styles.galesTitle}>
        Free pair of <GalesIcon className={styles.galesIcon} />
      </span>
    ),
    description: (
      <>
        Shoes designed for nurses, with comfort and protection, <span className={styles.bold}>worth $100</span>.
      </>
    ),
    showMoreContent: (
      <>
        Gales® is the first footwear designed to disperse energy and reduce impact on knees, backs, and joints from long
        12+ hour shifts, Gales was founded to support essential workers who deserve better care.
        <GalesShoes className={styles.galesShoes} />
        <div className={clsx(styles.highlighted, styles.visitLink)}>Visit Gales {'>'}</div>
      </>
    ),
    icon: <ShoesIcon />,
  },
];

const ReferralProgram = () => {
  const useShareModal = true;
  const [inviteEmail, setInviteEmail] = useState('');
  const [showShareModal, setShowShareModal] = useState(false);

  const { referralLink, waitListPosition, referralRewards } = useSelector(getCardData);
  const activeReward = getActiveReward(referralRewards);

  const isEmailValid = inviteEmail.match(EMAIL_PATTERN);

  const handleClick = () => {
    analytics.track('Card CTA Share Button Clicked');
    if (useShareModal) {
      setShowShareModal(true);
    } else {
      handleInvite();
    }
  };

  const handleInvite = () => {
    // TODO: Send email invite. Currently using share modal
  };

  return (
    <>
      <p className={styles.title}>Referral Program</p>
      <p className={styles.subtitle}>Earn rewards for each referral.</p>

      <div className={styles.rewardsStepper}>
        {rewards(waitListPosition).map((reward, index) => {
          const redeemed = referralRewards && referralRewards[index].redeemed;
          return (
            <RewardItem
              id={reward.id}
              title={reward.title}
              description={reward.description}
              showMoreContent={reward.showMoreContent}
              icon={reward.icon}
              redeemed={redeemed}
              key={reward.id}
              activeReward={activeReward}
            />
          );
        })}
      </div>

      <div className={styles.inviteContainer}>
        {!useShareModal && <p className={styles.inviteTitle}>Invite a friend or colleague:</p>}
        {!useShareModal && (
          <Input
            value={inviteEmail}
            onChange={(e) => setInviteEmail(e.target.value)}
            className={styles.input}
            placeholder="Email Address"
          />
        )}
      </div>

      <Button disabled={!isEmailValid && !useShareModal} onClick={handleClick} className={styles.button}>
        {useShareModal ? 'Invite friend or colleague' : 'Send Invite'}
      </Button>

      {showShareModal && (
        <ShareModal
          shareMessage={SHARE_MESSAGE}
          handleClose={() => setShowShareModal(false)}
          referralLink={referralLink || 'https://planneryapp.com'}
        />
      )}
    </>
  );
};

export default ReferralProgram;
