import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { coerceToPercent } from 'utils/coerceToPercent';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { createDocuSignEnvelope, updateApplicationStatus } from 'thunks';
import Button from 'components/Button';
import { RootState } from 'handlers';
import { getApplicationData } from 'selectors/getApplicationData';
import { getCardData } from 'selectors/getCardData';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { DocuSignDocument } from 'api/DocuSignEnvelopeApi';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';
import LinkButton from 'components/LinkButton';

import LoanBreakdown from 'components/LoanBreakdown';
import Checkbox from 'components/Checkbox';
import { CurrentFlow } from 'enums/CurrentFlow';
import { ButtonType } from 'components/Button/Button';
import { ConfirmLoanResult } from 'enums/FlowNextResults';

import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { ConversionType, trackConversion } from 'utils/analytics';
import { Environments, getEnvironment } from 'utils/getEnvironment';

import styles from './ConfirmLoan.module.scss';

const OUTPUT_VARIABLES_DECIMAL_PLACES = 2;

const ConfirmLoan = ({ navigationInfo, handleNext }: FlowComponentType): JSX.Element => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const { partner_branding: partnerBranding } = useSelector((state: RootState) => state.preQualificationData);

  const [agreementChecked, setAgreementChecked] = useState<boolean>(false);
  const [agreeToBeContactedChecked, setAgreeToBeContacted] = useState<boolean>(false);

  const { application, isApplicationStatusUpdating } = useSelector(getApplicationData);
  const { isLoading: isDocuSignEnvelopeLoading } = useSelector((state: RootState) => state.docuSignEnvelope);
  const { applied: cardApplied } = useSelector(getCardData);

  const isFinancialCheckup = application?.currentFlow === CurrentFlow.FinancialCheckup;

  const discounts: { name: string; value: number }[] =
    partnerBranding && getEnvironment() === Environments.Staging ? [{ name: 'Loyalty Discount', value: 0.01 }] : [];

  if (!application) {
    throw new Error('Missing application data');
  }

  const handleCreateDocuSignEnvelopment = async () => {
    const applicationId = application!.id;
    window.location.replace(
      await dispatchWithUnwrap(
        createDocuSignEnvelope({
          applicationId,
          windowLocationHref: window.location.href,
          document: DocuSignDocument.DraftLoanAgreement,
        }),
      ),
    );
  };

  const loanAmount = formatMonetaryAmount(application.loanAmount, false);
  const paymentPerPaycheck = formatMonetaryAmount(application.amountPerPaycheck, true);

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <div className={styles.container}>
        <h1 className={styles.title}>Your Plannery Offer</h1>
        <p className={styles.loanAmountTitle}>Debt Consolidation Amount</p>
        <div className={styles.loanAmountContainer}>
          <p className={styles.loanAmount}>{loanAmount}</p>
        </div>

        {discounts.length > 0 && (
          <div className={styles.discountSection}>
            <div className={styles.term}>
              <p className={styles.loanTermsTitle}>Your Discounted Rate</p>
              <p className={styles.termValue}>{coerceToPercent(application.apr, OUTPUT_VARIABLES_DECIMAL_PLACES)}%</p>
            </div>
            <>
              <div className={styles.termDiscount}>
                <p className={styles.termTitle}>Plannery Rate</p>
                <p className={styles.termValueStrike}>
                  {coerceToPercent(
                    application.apr! + discounts.reduce((sum, discount) => sum + discount.value, 0),
                    OUTPUT_VARIABLES_DECIMAL_PLACES,
                  )}
                  %
                </p>
              </div>
              {discounts.map((discount) => (
                <div className={styles.term}>
                  <p className={styles.termTitle}>{discount.name}</p>
                  <p className={styles.termValue}>
                    {coerceToPercent(discount.value, OUTPUT_VARIABLES_DECIMAL_PLACES)}%
                  </p>
                </div>
              ))}
            </>
          </div>
        )}

        <p className={styles.loanTermsTitle}>Loan Terms:</p>
        <>
          {application.apr && (
            <div className={styles.term}>
              <p className={styles.termTitle}>APR</p>
              <p className={styles.termValue}>{coerceToPercent(application.apr, OUTPUT_VARIABLES_DECIMAL_PLACES)}%</p>
            </div>
          )}
          {application.monthlyLoanPayment && (
            <div className={styles.term}>
              <p className={styles.termTitle}>Payment per Paycheck</p>
              <p className={styles.termValue}>{paymentPerPaycheck}</p>
            </div>
          )}
          {application.loanTermInMonths && (
            <div className={styles.term}>
              <p className={styles.termTitle}>Loan Term</p>
              <p className={styles.termValue}>{application.loanTermInMonths} months</p>
            </div>
          )}
        </>
        <LoanBreakdown application={application} container={styles.loanBreakdownContainer} title="Loan Breakdown:" />
        <LinkButton
          onClick={handleCreateDocuSignEnvelopment}
          className={styles.anchorButton}
          isLoading={isDocuSignEnvelopeLoading}
        >
          Show me my draft Loan Agreement
        </LinkButton>
        {isFinancialCheckup && (
          <div className={styles.checkboxesContainer}>
            <Checkbox
              onChange={() => setAgreementChecked(!agreementChecked)}
              checked={agreementChecked}
              className={styles.checkbox}
              label={
                <span>
                  I hereby consent to the{' '}
                  <a href="https://www.planneryapp.com/esign-agreement" target="_blank" rel="noreferrer">
                    E-Sign Agreement
                  </a>{' '}
                  and{' '}
                  <a href="https://www.planneryapp.com/terms-of-service" target="_blank" rel="noreferrer">
                    Terms of Service
                  </a>
                  , and I am providing written consent under the FCRA for Plannery to obtain consumer report information
                  from my credit profile.
                </span>
              }
            />

            <Checkbox
              onChange={() => setAgreeToBeContacted(!agreeToBeContactedChecked)}
              checked={agreeToBeContactedChecked}
              className={styles.checkbox}
              label={
                <span>
                  I agree to be contacted by Plannery via email and/or at the number I provided to explore about the
                  financial product I enquired about, including contact through auto dialing systems, artificial or
                  pre-recorded voice messaging or text messages.
                </span>
              }
            />
          </div>
        )}
        <Button
          className={styles.button}
          onClick={async () => {
            trackConversion(ConversionType.ConfirmedLoan);
            await dispatchWithUnwrap(
              updateApplicationStatus({
                applicationId: application!.id,
                applicationStatus: ApplicationStatusName.Verification,
              }),
            );
            handleNext(ConfirmLoanResult.Continue);
          }}
          isLoading={isApplicationStatusUpdating}
          disabled={isFinancialCheckup && (!agreeToBeContactedChecked || !agreementChecked)}
        >
          Next
        </Button>
        {cardApplied && (
          <Button
            type={ButtonType.Inverted}
            className={styles.buttonMarginTop}
            onClick={() => {
              handleNext(ConfirmLoanResult.BackToCard);
            }}
          >
            Not Now
          </Button>
        )}
      </div>
    </>
  );
};

export default ConfirmLoan;
