import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConversionType, trackConversion } from 'utils/analytics';
import { CardColor } from 'components/CardFlow/Customize/cardVersions';
import { getCardDataForFinancialCheckupApplication } from 'thunks';

import { DebtSummary, GetApplicationData } from './applicationData';

export enum CardDataVariable {
  Credentials = 'credentials',
}

export enum ReferralReward {
  MoveUpWaitlist = 'MoveUpWaitlist',
  CameoFeaturing = 'CameoFeaturing',
  FreeGales = 'FreeGales',
}

export interface ReferralProgramReward {
  id: string;
  enabled: boolean;
  redeemed: boolean;
}

export interface CardDataFetchStatus {
  isLoading: boolean;
  error: boolean;
  fetched: boolean;
}

export interface ReferralData {
  firstName: string;
  lastName: string;
  httpReferrer: string;
}

export interface CardData {
  applied?: boolean;
  referredBy?: string;
  referralLink?: string;
  isQualified?: boolean;
  hasAlreadyApplied?: boolean;
  cardColor?: CardColor;
  debtSummary?: DebtSummary;
  debtConsolidationPossible?: boolean;
  waitListPosition?: number;
  borrowerCredentials?: string;
  initialReferrer?: string;
  referralRewards?: ReferralProgramReward[];
  referredApplicants?: ReferralData[];
}

type CardDataState = CardData & CardDataFetchStatus;

const initialState: CardDataState = {
  applied: false,
  isLoading: false,
  error: false,
  fetched: false,
};

const cardData = createSlice({
  name: 'card',
  initialState,
  reducers: {
    setCardData: (state: CardData, { payload }: PayloadAction<CardData>) => {
      if (payload.applied && !payload.hasAlreadyApplied) {
        trackConversion(ConversionType.CardApplied);
        if (payload.isQualified) {
          trackConversion(ConversionType.CardAppliedAndQualified);
        }
      }
      payload.applied !== undefined && (state.applied = payload.applied);
      payload.referredBy && (state.referredBy = payload.referredBy);
      payload.referralLink && (state.referralLink = payload.referralLink);
      payload.cardColor && (state.cardColor = payload.cardColor);
      state.debtSummary = payload.debtSummary;
      state.debtConsolidationPossible = payload.debtConsolidationPossible;
      payload.waitListPosition && (state.waitListPosition = payload.waitListPosition);
      payload.initialReferrer && (state.initialReferrer = payload.initialReferrer);
      payload.borrowerCredentials && (state.borrowerCredentials = payload.borrowerCredentials);
      payload.referralRewards && (state.referralRewards = payload.referralRewards);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCardDataForFinancialCheckupApplication.pending, (state) => {
      state.isLoading = true;
      state.fetched = false;
    });
    builder.addCase(
      getCardDataForFinancialCheckupApplication.fulfilled,
      (state, { payload }: PayloadAction<GetApplicationData>) => {
        state.isLoading = false;
        state.fetched = true;

        state.cardColor = payload.cardColor;
        state.waitListPosition = payload.cardWaitListPosition;
        payload.cardReferralRewards && (state.referralRewards = JSON.parse(payload.cardReferralRewards as string));
        payload.cardReferredApplicants &&
          (state.referredApplicants = JSON.parse(payload.cardReferredApplicants as string));
        state.borrowerCredentials = payload.borrowerCredentials;
      },
    );
    builder.addCase(getCardDataForFinancialCheckupApplication.rejected, (state) => {
      state.isLoading = false;
      state.error = true;
      state.fetched = false;
    });
  },
});

export const { setCardData } = cardData.actions;

export default cardData.reducer;
