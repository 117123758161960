import React, { useEffect } from 'react';
import FormNavigation from 'components/FormNavigation';
import Button from 'components/Button';
import { useSelector } from 'react-redux';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { ReactComponent as CheckIcon } from 'images/green-check-rounded.svg';
import { ReactComponent as WarningIcon } from 'images/warning-icon.svg';
import { getApplicationData } from 'selectors/getApplicationData';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import {
  getStateMinimumAmount,
  MinimumAmountsPerState,
} from 'components/HardOffer/components/PayOffPlan/components/DebtConsolidationDropdown';
import clsx from 'clsx';
import StatusButton from 'components/Common/Buttons/StatusButton';
import { FlowComponentType } from 'routes/FlowRouter';
import { DebtRecommendation, DebtSummary, HardOfferData, HardOfferSummary, LoanType } from 'handlers/applicationData';
import { getRemainingTerm } from 'utils/dateUtils';
import { useNavigate } from 'hooks/useNavigate';
import { RoutePath } from 'enums/Routes';
import { getCardData } from 'selectors/getCardData';

import DebtRow from './DebtRow';
import PlanneryCardInformation from './PlanneryCardInformation';
import { getDebtCategories, REVIEW_STATUSES } from './yourFinancesUtils';
import PlanneryCardSummary from './PlanneryCardSummary/PlanneryCardSummary';

import styles from './YourFinances.module.scss';

const getOfferLabels = (
  shouldOfferPlanneryLoan: boolean,
  debtSummary: DebtSummary,
  offerSummary: HardOfferSummary,
): {
  offerLabel: JSX.Element;
  cardTextLabel: string | undefined;
} => {
  if (shouldOfferPlanneryLoan) {
    const { amount: term, timeFrame } = getRemainingTerm(offerSummary.monthsSaved);
    return {
      offerLabel: (
        <span>
          Because you're a healthcare worker, Plannery can save you{' '}
          <span className={styles.highlighted}>{formatMonetaryAmount(offerSummary?.moneySaved)}</span> and get you out
          of debt{' '}
          <span className={styles.highlighted}>
            {term} {timeFrame} earlier
          </span>
          !
        </span>
      ),
      cardTextLabel: undefined,
    };
  }

  const cardOffer = debtSummary[LoanType.CreditCard]?.cardOffer;

  // If there is no debt comparison, state interest rates up to 50% below market.
  if (!cardOffer) {
    return {
      offerLabel: (
        <span>
          Because you're a healthcare worker, Plannery can reward you for paying these down and offer you interest rates
          up to 50% below market.
        </span>
      ),
      cardTextLabel: 'Interest rates up to 50% below market',
    };
  }

  const { primaryCardId } = cardOffer;
  const primaryCard = debtSummary[LoanType.CreditCard].tradelines.find(({ id }) => id === primaryCardId)!;

  const interestDifference = Math.floor(
    ((primaryCard.totalInterest! - cardOffer.totalInterest!) / primaryCard.totalInterest!) * 100,
  );

  // If the interest difference is less than 10%, then don't show the APR difference
  if (interestDifference >= 10) {
    const cardName = `${primaryCard.firm}${primaryCard.firm.toLowerCase().includes('card') ? '' : ' Card'}`;

    return {
      offerLabel: (
        <span>
          Because you're a healthcare worker, Plannery can reward you for paying these down and offer you{' '}
          {interestDifference}% less interest than your {cardName}.
        </span>
      ),
      cardTextLabel: `${interestDifference}% less interest than your ${cardName}`,
    };
  }

  return {
    offerLabel: <span>Because you're a healthcare worker, Plannery can reward you for paying these down.</span>,
    cardTextLabel: undefined,
  };
};

const YourFinances = ({ handleNext }: FlowComponentType): JSX.Element => {
  const navigate = useNavigate();
  const { application } = useSelector(getApplicationData);
  const { applied: cardApplied } = useSelector(getCardData);

  const offerSummary = (application?.hardOffer as HardOfferData)?.offerSummary;
  const debtSummary = application?.debtSummary as DebtSummary;

  const totalDebt = Object.values(debtSummary).reduce((total, debt) => total + debt.totalBalance, 0);

  const totalDebtToConsolidate =
    offerSummary?.loanAmount ||
    Object.values(debtSummary)
      .filter((debt) => debt.recommendation === DebtRecommendation.PayOff)
      .reduce((total, debt) => total + debt.totalBalance, 0);

  const { payOffDebts, keepItDebts } = getDebtCategories(debtSummary);

  const minimumDebt = getStateMinimumAmount(application?.borrowerStateOrProvince!) || MinimumAmountsPerState.FL;
  const debtLessThanStateMinimum = totalDebtToConsolidate < minimumDebt;

  const shouldOfferPlanneryLoan = application?.status === ApplicationStatusName.OfferAvailable;

  const { offerLabel, cardTextLabel } = getOfferLabels(shouldOfferPlanneryLoan, debtSummary, offerSummary!);

  const onNext = () => {
    if (shouldOfferPlanneryLoan) {
      handleNext();
    } else {
      navigate(RoutePath.CardInfo);
    }
  };

  useEffect(() => {
    if (shouldOfferPlanneryLoan) {
      analytics.track('Debt Consolidation CTA Viewed', {
        status: application?.status,
      });
    }
  }, []);

  const overchargedTitle = shouldOfferPlanneryLoan ? 'You’re being overcharged!' : 'Consider paying these off ASAP';

  const header = (
    <div
      className={clsx(styles.header, {
        [styles.centered]: debtLessThanStateMinimum,
      })}
    >
      {debtLessThanStateMinimum ? (
        <>
          <p className={styles.sectionTitle}>Your debt profile looks great!</p>
          <CheckIcon className={styles.icon} />
        </>
      ) : (
        <>
          <p className={styles.totalDebtTitle}>Your Total Debt</p>
          <p className={styles.totalDebtAmount}>{formatMonetaryAmount(totalDebt)}</p>
        </>
      )}
    </div>
  );

  const offerButton =
    shouldOfferPlanneryLoan &&
    (REVIEW_STATUSES.includes(application?.status as ApplicationStatusName) ? (
      <StatusButton label="In Review" icon={<CheckIcon />} />
    ) : (
      <Button onClick={onNext}>
        {application?.status === ApplicationStatusName.OfferAvailable ? 'See How' : 'Continue Applying'}
      </Button>
    ));

  return (
    <div className={styles.container}>
      <FormNavigation showBackLink={false} title="Financial Checkup" />
      {cardApplied && <PlanneryCardSummary />}
      <div className={styles.innerContainer}>{header}</div>

      {keepItDebts.length > 0 && (
        <div className={styles.innerContainer}>
          <div className={styles.header}>
            <p className={styles.sectionTitle}>These categories look OK</p>
            <CheckIcon className={styles.icon} />
          </div>
          {/* Category dropdown */}
          {keepItDebts.map((debt: any, index: any) => (
            <DebtRow index={index} debt={debt} totalBalance={totalDebt} key={debt.name.trim()} />
          ))}
        </div>
      )}

      {debtLessThanStateMinimum ? (
        <div className={clsx(styles.innerContainer, styles.centered)}>
          <PlanneryCardInformation cardTextLabel={cardTextLabel} />
        </div>
      ) : (
        <div className={styles.innerContainer}>
          <div className={styles.header}>
            <p className={styles.sectionTitle}>{overchargedTitle}</p>
            <WarningIcon className={styles.icon} />
          </div>

          {/* Overcharged dropdown */}
          {payOffDebts.map((debt: any, index: any) => (
            <DebtRow index={index} debt={debt} totalBalance={totalDebt} key={debt.name.trim()} />
          ))}

          <p className={styles.offerLabel}>{offerLabel}</p>

          <div className={styles.button}>{offerButton}</div>

          {!shouldOfferPlanneryLoan && !debtLessThanStateMinimum && <PlanneryCardInformation short />}
        </div>
      )}
    </div>
  );
};

export default YourFinances;
